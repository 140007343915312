import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const faqItems = [
    {
      question: 'What is the starting price for a basic website?',
      answer: 'Our basic website starts at $750, which includes a free domain and hosting for 1 year, along with a hero section, about us section, and more. If you require additional customizations, pricing may vary.'
    },
    {
      question: 'Do you provide custom web development solutions?',
      answer: 'Yes, we specialize in building custom web applications and websites tailored to your specific business needs. Our prices vary based on the complexity and requirements of your project.'
    },
    {
      question: 'What is included in your digital marketing services?',
      answer: 'Our digital marketing services include SEO, social media marketing, email marketing, and paid advertising. We help businesses generate leads and increase their online presence.'
    },
    {
      question: 'How can I schedule a consultation for AI consulting?',
      answer: 'You can schedule a consultation by filling out the contact form on our website. We’ll reach out to discuss how AI can be integrated into your business operations for efficiency and growth.'
    },
    {
      question: 'What’s the difference between Standard and Premium pricing plans?',
      answer: 'The Standard plan starts at $1,500 and includes additional features like contact forms, social media integration, and Google Maps. The Premium plan, starting at $2,500, offers more customization and advanced options like Google Business Profile and additional customized sections.'
    },
    {
      question: 'What does your branding service include?',
      answer: 'Our branding services cover everything from logo design, color schemes, typography, and messaging to ensure your brand identity is strong across all digital and physical platforms.'
    },
    {
      question: 'Do you offer ongoing website maintenance and support?',
      answer: 'Yes, we offer maintenance and support services for all websites we develop to ensure they stay up-to-date, secure, and functioning properly. Our Premium and Elite plans include monthly support.'
    },
    {
      question: 'How do I get a custom quote for my project?',
      answer: 'If you need a higher level of customization or more advanced features, you can contact us for a free consultation. We’ll assess your needs and provide a custom quote based on your requirements.'
    },
    {
      question: 'Can I upgrade my website later if I start with a basic package?',
      answer: 'Absolutely! You can always upgrade your website to include more features as your business grows. We offer flexible upgrade options for all our pricing plans.'
    },
    {
      question: 'What physical branding solutions do you offer?',
      answer: 'Our complete branding solutions include custom signage, t-shirts, stickers, banners, and car decals to help your brand stand out in both digital and physical spaces.'
    }
  ];

// Step 2: Map through the array to render the Accordion
const FAQ = () => {
  return (
    <div className="container-fluid bg-light pb-5 pt-4 mw-100 border p-5">
      <div className="container text-center pt-3 pb-5">
        <h1 className="display-3 font-weight-bold text-muted">FAQs</h1>
        <p className='lead text-muted'>Have questions about our services? Check out our most commonly asked questions below.
        If you need further assistance, feel free to reach out to us!
        </p>
        </div>
    <Accordion flush>
      {faqItems.map((item, index) => (
        <Accordion.Item eventKey={index.toString()} key={item.id}>
          <Accordion.Header className='display-3'>{item.question}</Accordion.Header>
          <Accordion.Body>{item.answer}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
    </div>
  );
};

export default FAQ;
