import React from "react";
import NavBar from "./NavBar";
import { useState, useEffect } from "react";
import { createClient } from "contentful";
import { useParams } from "react-router-dom";
import RichText from "../../helper_functions/RichText";
import Footer from "../Footer/Footer";
import './blog.css'

const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
  });

export default function Post() {
  const { postId } = useParams();
  const [post, setPost] = useState({});

  useEffect(() => {
    client
      .getEntry(postId)
      .then((response) => {
        setPost(response.fields);
      })
      .catch((error) => {
        console.log('There was an error');
      });
  }, [postId]);

  return (
    <div className="main">
      <NavBar />
      {!post ? (
        <> </>
      ) : (
        <div className="container bg-light p-5">
          {!post.titleImage ? <></> : <img
            className="img-fluid image"
            src={post.titleImage.fields.file.url}
            alt={post.title}
          ></img> }
          <div className="display-5 mt-5">{post.title}</div>
          <div className="row">
            <div className="col">
            <p className="card-text mb-5">
              <small class="text-muted">{`By: ${post.author} `}</small>
            </p>
          </div>
            <div className="col">{}</div>
          </div>
          <RichText json={post.articleBody} />
        </div>
      )}
      <Footer />
    </div>
  );
}
