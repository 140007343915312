import { Button, Form } from 'react-bootstrap'
import { useEffect, useState } from "react";
import { useFirestore } from '../../hooks/useFirestore';
import useForm from '../../hooks/useForm';
import './contact.css'
import AOS from "aos";
import "aos/dist/aos.css";
import MyModal from '../Modal/MyModal';

export default function Contact(){

    const { addDocument } = useFirestore('new-customers')
    const [ validated, setValidated ] = useState(false)
    const [ showModal, setShowModal] = useState(false)

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    const callback = () => {
      addDocument(values)
      setShowModal(true)
      setValidated(false)
    }
    const {handleChange, handleSubmit, values, errors } = useForm(callback);

    return(
        <>
          <div id='contact' className="container-fluid">
              <div className="container text-center text-muted pt-5 w-75">
                <h1 className="display-3">How can we help you?</h1>
                <p className="lead pt-3 pb-3">Please fill out the form, one of our specialists will contact you soon
                to start a free consultation.</p>
              </div>
              <div className='container form bg-light p-4 shadow-lg rounded-3 border border-1 border-secondary' data-aos="fade-up" data-aos-duration="1000">
                <Form validated={validated} onSubmit={handleSubmit} >
                    <Form.Control required  value = {values.name} name='name' onChange={handleChange} className='mb-3 lead' type='text' placeholder='Name' />
                      {errors.name && <p className='text-danger'>{`*${errors.name}`}</p>}
                    <Form.Control required value={values.email} onChange={handleChange} className='mb-3 lead' name='email' type='email' placeholder='Email' />
                      {errors.email && <p className='text-danger'>{`*${errors.email}`}</p>}
                    <Form.Control required value={values.phone} name='phone' onChange={handleChange} className='mb-3 lead' type='phone'placeholder='Phone Number' />
                      {errors.phone && <p className='text-danger'>{`*${errors.phone}`}</p>}
                    <Form.Control required value={values.business} name='business' onChange={handleChange} className='mb-3 lead' type='text' placeholder='Business name' />      
                      {errors.business && <p className='text-danger'>{`*${errors.business}`}</p>}
                    <Form.Control required value={values.request} name='request' onChange={handleChange} className='mb-3 lead' as={'textarea'} placeholder='Please tell us what you need. New Website, logo design, etc.'/>  
                      {errors.request && <p className='text-danger'>{`*${errors.request}`}</p>}
                    
                    <Button type='submit' className='btn btn-danger mt-3 submit-button'>Send Message</Button>
                </Form>
              
              </div>
          </div>
          <MyModal name={values.name} show={showModal} onHide={() => {setShowModal(false)}}>
            <div className='text-center'>          
              <i className="bi bi-check2-circle checkmark text-success"></i>
              <h4 className="display-4">Thank you!</h4>
              <p className="lead">
               One of our specialists will contact you soon.</p>
            </div>

          </MyModal>
        </>
    )
}