import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import { createClient } from "contentful";
import BlogCard from "./BlogCard";
import Footer from "../Footer/Footer";
import './blog.css'

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
});

export default function Blog() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    client
      .getEntries({
        content_type: "blog",
        order: '-fields.published'
      })
      .then((response) => {
        setPosts(response.items);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="main">
      <NavBar />
      <div className="container bg-light rounded-3">
        <h1 className="display-6 text-center pt-3">Blog Posts</h1>
        <div className="row pb-4">
          {!posts ? (
            <></>
          ) : (
            posts.map((post) => (
              <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                <BlogCard post={post} />
              </div>
            ))
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
