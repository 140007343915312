import React from "react";

export default function BlogCard(props) {
  const post = props.post;
  const date = new Date(post.fields.published);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  return (
    <div className="card p-3 shadow-lg">
      <div key={post.sys.id}>
        <div className="row">
          <div className="col">
            <img
              className="img-thumbnail"
              src={post.fields.titleImage.fields.file.url}
              alt={post.fields.title}
            />
          </div>
          <div className="col my-auto">
            <h2 className="text-dark lead ">{post.fields.title}</h2>
            <p className="card-text">
              <small class="text-muted">{`${
                post.fields.author
              } | ${date.toLocaleDateString("en-US", options)}`}</small>
            </p>
            <a
              className="text-danger"
              style={{ textDecoration: "none" }}
              href={`/blogs/${post.sys.id}`}
            >
              Read more...
            </a>
          </div>
        </div>

        
        {/* <p>{post.fields.body}</p> */}
      </div>
    </div>
  );
}
