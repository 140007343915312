import { Card } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import './results.css'

export default function Analytics() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

    return(
    <>
        <div id="analytics" className="container-fluid pt-5 pb-5 text-muted">
            <div className="container text-white text-center pb-4">
              <h1 className="display-3 ">Analitica</h1>
                <p className="lead">
                    Una campaña de marketing o un nuevo sitio web están diseñados para atraer y retener 
                    clientes. En Barcias entendemos que cada dólar que inviertes en esa meta cuenta. 
                    Ofrecemos informes mensuales para que su equipo pueda tomar decisiones inteligentes 
                    e invertir en marketing o funciones que realmente funcionen para alcanzar 
                    sus objetivos.
                </p>
            </div>
            <div className="container bg-light pt-4 rounded-3 text-center" data-aos="fade-in">
              <div className="row p-4">
                <div className="col-sm-4 pt-4">
                <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require('./Assets/smartphone.png')} />
                    <Card.Body>
                      <Card.Text className="lead">Dispositivos</Card.Text>
                      <Card.Text className="mb-5">
                      Tipo de dispositivos utilizados por sus usuarios. ¿Están accediendo a su sitio web desde una computadora o un teléfono inteligente?
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-4 pt-4">
                <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require("./Assets/laptop.png")} />
                    <Card.Body>
                    <Card.Text className="lead">Trafico Web</Card.Text>
                      <Card.Text className="mb-5">
                      Si tiene varias aplicaciones o páginas, los informes mensuales muestran qué aplicación o página atrae más tráfico.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-4 pt-4">
                <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require('./Assets/demographics.png')} />
                    <Card.Body>
                    <Card.Text className="lead">Datos Demográficos</Card.Text>
                      <Card.Text className="mb-5">
                       
Conozca a sus clientes, nuestros informes incluyen datos demográficos (por grupo de edad) cuando los datos están disponibles.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>

              <div className="row p-4">
                <div className="col-sm-4 pt-4">
                  <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require('./Assets/users.png')} />
                    <Card.Body>
                    <Card.Text className="lead">Actividad de Usuarios</Card.Text>
                      <Card.Text className="mb-5">
                      Realice un seguimiento de la actividad de los usuarios a lo largo del tiempo, incluidos sus usuarios activos diarios y la retención de usuarios.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-4 pt-4">
                <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require('./Assets/timer.png')}/>
                    <Card.Body>
                    <Card.Text className="lead">Compromiso</Card.Text>
                      <Card.Text className="mb-5">
                      Compromiso del usuario, cuánto tiempo y con qué frecuencia los usuarios visitan su sitio web.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-4 pt-4">
                <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require('./Assets/world-map.png')} />
                    <Card.Body>
                    <Card.Text className="lead">Paises</Card.Text>
                      <Card.Text className="mb-5">
                       
                      ¿Intenta llegar a clientes internacionales? Mostraremos el tráfico del sitio web por país.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>

            </div>
        </div>
    </>)
}