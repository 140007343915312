// components/Ticker.js
import { useEffect, useState, useMemo } from 'react';
import './ticker.css'; // Ensure this path is correct based on your project structure

export default function Ticker() {
  const [tickerText, setTickerText] = useState('');
  const tickerMessages = useMemo(() => [
    'Limited time offer for new customers: Get a full branding package! Special deal: 15 T-shirts, 100 stickers or Business cards, 10 Facebook posts, and a new website for $2000! Contact us today to learn more about our services!'
  ], []); // Memoizing the array
  // Function to handle the ticker scrolling effect
  useEffect(() => {
    let currentIndex = 0;

    const updateTicker = () => {
      setTickerText(tickerMessages[currentIndex]);
      currentIndex = (currentIndex + 1) % tickerMessages.length;
    };

    const tickerInterval = setInterval(updateTicker, 10); // Change every 4 seconds
    updateTicker(); // Set initial text immediately

    return () => clearInterval(tickerInterval);
  }, [tickerMessages]); 
  return (
    <div className="ticker-container">
      <div className="ticker">
        <span className="ticker-text">{tickerText}</span>
      </div>
    </div>
  );
}
