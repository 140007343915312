import { Card } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import './results.css'

export default function Analytics() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

    return(
    <>
        <div id="analytics" className="container-fluid pt-5 pb-5 text-muted">
            <div className="container text-white text-center pb-4">
              <h1 className="display-3 ">Analytics</h1>
                <p className="lead">A marketing campaing or a new website are design to 
                  attract and retain customers. At Barcias we understand that each dollar you invest 
                  towards that goal counts. We offer monthly reports so that your team
                  can make smart choices and invest in marketing or features that actually
                  work towards your goals. 
                </p>
            </div>
            <div className="container bg-light pt-4 rounded-3 text-center" data-aos="fade-in">
              <div className="row p-4">
                <div className="col-sm-4 pt-4">
                <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require('./Assets/smartphone.png')} />
                    <Card.Body>
                      <Card.Text className="lead">Devices</Card.Text>
                      <Card.Text className="mb-5">
                        Type of devices used by your users. Are they accessing your website from a computer or smartphone?
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-4 pt-4">
                <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require("./Assets/laptop.png")} />
                    <Card.Body>
                    <Card.Text className="lead">Traffic</Card.Text>
                      <Card.Text className="mb-5">
                        If you have multiple apps or pages, monthly reports shows which app or page attacts more traffic.  
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-4 pt-4">
                <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require('./Assets/demographics.png')} />
                    <Card.Body>
                    <Card.Text className="lead">Demographics</Card.Text>
                      <Card.Text className="mb-5">
                        Know your customers, our reports include demographics (by age group) when data is available. 
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>

              <div className="row p-4">
                <div className="col-sm-4 pt-4">
                  <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require('./Assets/users.png')} />
                    <Card.Body>
                    <Card.Text className="lead">User Activity</Card.Text>
                      <Card.Text className="mb-5">
                        Track user activity over time, including
                        your daily active users and user retention.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-4 pt-4">
                <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require('./Assets/timer.png')}/>
                    <Card.Body>
                    <Card.Text className="lead">Engagement</Card.Text>
                      <Card.Text className="mb-5">
                        User engagement, how long and how often users are visiting your website. 
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-4 pt-4">
                <Card className="shadow-lg pt-4 my-3" data-aos="zoom-in-up" data-aos-duration="1000">
                  <Card.Img variant="top" className="card-image shadow-lg" src={require('./Assets/world-map.png')} />
                    <Card.Body>
                    <Card.Text className="lead">Countries</Card.Text>
                      <Card.Text className="mb-5">
                        Trying to reach international customers? We'll show web site traffic by country.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>

            </div>
        </div>
    </>)
}