import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  ListGroup,
} from "react-bootstrap";
import { FaGlobe, FaTshirt, FaBusinessTime, FaTruck } from "react-icons/fa";

const PackageSection = () => {
  return (
    <Container className="py-5 my-5" id="package-section">
      <div className="bg-light p-5 shadow-lg">
      <Row className="text-center mb-4">
        <Col>
          <h2 className="display-4 text-danger fw-bold">
            Launch Your Brand with Our Complete Package
          </h2>
          <p className="lead text-muted">
            Website, T-shirts, and Stickers – All for $2,000!
          </p>
        </Col>
      </Row>
      <Row className="g-4">
        {/* Website Card */}
        <Col lg={4} md={6}>
          <Card className="h-100 shadow-lg">
            <Image
              variant="top"
              src={require("./Assets/digital.jpg")}
              alt="Website"
              fluid
            />
            <Card.Body>
              <Card.Title>Responsive Website</Card.Title>
              <Card.Text>
                A modern, fully responsive landing page designed to showcase
                your brand and attract new customers.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        {/* T-shirts Card */}
        <Col lg={4} md={6}>
          <Card className="h-100 shadow-lg">
            <Image
              variant="top"
              src={require("./Assets/shirts.png")}
              alt="Custom T-shirts"
              fluid
            />
            <Card.Body>
              <Card.Title>Custom T-shirts</Card.Title>
              <Card.Text>
                15 premium t-shirts (sizes L or M) featuring your logo to create
                a cohesive and professional look for your team.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        {/* Stickers Card */}
        <Col lg={4} md={6}>
          <Card className="h-100 shadow-lg">
            <Image
              variant="top"
              src={require("./Assets/stickers.png")}
              alt="Custom Stickers"
              fluid
            />
            <Card.Body>
              <Card.Title>Custom Stickers or Business Cards</Card.Title>
              <Card.Text>
                100 high-quality, 2x2 inch stickers featuring your logo and a
                scannable QR code to promote your brand everywhere.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Add-ons Section */}
      <Row className="text-center mt-5">
        <Col>
          <h3 className="display-6 text-danger">
            Want More? Customize Your Package!
          </h3>
          <p className="lead text-muted">
            Choose from our optional upgrades to maximize your impact.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center mt-4">
        <Col md={8}>
          <Card className="shadow-sm p-3">
            <ListGroup variant="flush">
              <ListGroup.Item className="d-flex align-items-center">
                <FaGlobe size={48}  className="me-3 text-danger" />
                <strong>Additional Website Pages:</strong> Add more sections or
                pages to your website at a discounted rate.
              </ListGroup.Item>
              <ListGroup.Item className="d-flex align-items-center">
                <FaTshirt size={48} color="red" className="me-3 text-danger" />
                <strong>More T-shirts or Stickers:</strong> Increase your
                visibility with additional t-shirts or stickers at special
                rates.
              </ListGroup.Item>
              <ListGroup.Item className="d-flex align-items-center">
                <FaBusinessTime size={48} color="red" className="me-3 text-danger" />
                <strong>Business Cards or Car Decals:</strong> Elevate your
                brand with professional business cards or car decals.
              </ListGroup.Item>
              <ListGroup.Item className="d-flex align-items-center">
                <FaTruck size={48} color="red" className="me-3 text-danger" />
                <strong>Vinyl Letters for Car or Window:</strong> Elevate your
                brand with professional car decals or window lettering.
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>

      <Row className="text-center mt-5">
        <Col>
          <a className="btn btn-lg btn-danger" href="#contact">
            Get Started Now for $2,000!
          </a>
        </Col>
      </Row>
      </div>
    </Container>
  );
};

export default PackageSection;
