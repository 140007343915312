import './navbar_blog.css'
import { Navbar, Container, Nav, NavbarBrand, NavDropdown } from 'react-bootstrap'


export default function NavBar(){
    return (
        <Navbar className="navbar" collapseOnSelect expand='md'>
          <Container>
          <NavbarBrand href="/">
            <img
              src={require('./Assets/BarciaDigital.png')}
              width="150"
              height="75"
              className="d-inline-block align-top"
              alt="Barcias logo"
            /></NavbarBrand>
            <Navbar.Toggle className='toggle-toggle' aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className='collapse' id='responsive-navbar-nav' >
              <Nav className="ms-auto">
               <Nav.Link id='navlinks' href="/">Home</Nav.Link>
              </Nav>
              <Nav>
               <Nav.Link id='navlinks' href="/#services">Services</Nav.Link>
              </Nav>
              <Nav>
               <Nav.Link id='navlinks' href="/#process">Process</Nav.Link>
              </Nav>
              <Nav>
               <Nav.Link id='navlinks' href="/#analytics">Analytics</Nav.Link>
              </Nav>
              <Nav>
               <Nav.Link id='navlinks' href="/#pricing">Pricing</Nav.Link>
              </Nav>
              <Nav>
               <Nav.Link id='navlinks' href="/blog">Blog</Nav.Link>
              </Nav>
              <Nav>
               <Nav.Link id='navlinks' href="/#contact">Contact</Nav.Link>
              </Nav>
              <NavDropdown title={<img className="flag-image" 
                 src={require('./Assets/usa.png')} 
                 alt="user pic"/>}  id="basic-nav-dropdown">
                <NavDropdown.Item href="/"><span><img   className='flag-image' src={require('../Blog/Assets/usa.png')} alt='usa'></img></span> English</NavDropdown.Item>
                <NavDropdown.Item href="/es"><span><img className='flag-image' src={require('../Blog/Assets/mexico.png')} alt='usa'></img></span> Español</NavDropdown.Item>
              </NavDropdown>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    )
}