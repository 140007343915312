import React from "react";
import { Container, Row, Col, Card, Image, ListGroup } from "react-bootstrap";

const PackageSection = () => {
  return (
    <Container className="py-5 my-5" id="package-section">
      <Row className="text-center mb-4">
        <Col>
          <h2 className="display-4">Lanza Tu Marca con Nuestro Paquete Completo</h2>
          <p className="lead text-muted">¡Sitio web, camisetas y stickers – Todo por $2,000!</p>
        </Col>
      </Row>
      <Row className="g-4">
        {/* Website Card */}
        <Col lg={4} md={6}>
          <Card className="h-100 shadow-lg">
            <Image
              variant="top"
              src={require("./Assets/digital.jpg")}
              alt="Página web"
              fluid
            />
            <Card.Body>
              <Card.Title>Sitio Web Responsivo</Card.Title>
              <Card.Text>
                Una página de aterrizaje moderna y completamente responsiva, diseñada para mostrar tu marca y atraer nuevos clientes.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        {/* T-shirts Card */}
        <Col lg={4} md={6}>
          <Card className="h-100 shadow-lg">
            <Image
              variant="top"
              src={require("./Assets/shirts.png")}
              alt="Camisetas personalizadas"
              fluid
            />
            <Card.Body>
              <Card.Title>Camisetas Personalizadas</Card.Title>
              <Card.Text>
                15 camisetas premium (tallas L o M) con tu logotipo para crear una apariencia cohesiva y profesional para tu equipo.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        {/* Stickers Card */}
        <Col lg={4} md={6}>
          <Card className="h-100 shadow-lg">
            <Image
              variant="top"
              src={require("./Assets/stickers.png")}
              alt="Stickers personalizados"
              fluid
            />
            <Card.Body>
              <Card.Title>Stickers Personalizados</Card.Title>
              <Card.Text>
                100 stickers de alta calidad de 2x2 pulgadas con tu logotipo y un código QR escaneable para promocionar tu marca en todas partes.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Add-ons Section */}
      <Row className="text-center mt-5">
        <Col>
          <h3 className="display-6 text-danger">¿Quieres Más? ¡Personaliza Tu Paquete!</h3>
          <p className="lead text-muted">Elige entre nuestras opciones adicionales para maximizar tu impacto.</p>
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <Col md={8}>
          <Card className="shadow-sm p-3">
            <ListGroup variant="flush">
              <ListGroup.Item>
                <strong>🌐 Más Páginas Web: </strong> Añade más secciones o páginas a tu sitio web por un precio reducido.
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>👕 Más Camisetas o Stickers: </strong> Aumenta tu visibilidad con más camisetas o stickers a tarifas especiales.
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>📇 Tarjetas de Presentación o Calcomanías para Autos: </strong> Eleva tu marca con tarjetas profesionales o calcomanías de vehículos.
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>

      <Row className="text-center mt-5">
        <Col>
          <a className="btn btn-lg btn-danger" href="#contact">
            ¡Empieza Ahora por $2,000!
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default PackageSection;
