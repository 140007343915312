import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import "./pricing.css";
import { PopupButton } from "react-calendly";

export default function PricingModern() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const pricingPlans = [
    {
      title: "Starter",
      price: "$750",
      startingAt: "starting at",
      features: [
        { text: "$25 monthly", available: true },
        { text: "Free Domain for 1 year", available: true },
        { text: "Modern Landing Page", available: true },
        { text: "Social Media Integration", available: false },
        { text: "Technical Support", available: false },
        { text: "No Contact Form", available: false },
        { text: "No Google Map", available: false },
        { text: "No Customize Sections", available: false },
        { text: "No E-commerce", available: false },
        { text: "No CMS", available: false },
      ],
      buttonLink: "https://calendly.com/barciastech/30min",
      buttonText: "Book a FREE Consultation",
    },
    {
      title: "Pro",
      price: "$1,500",
      startingAt: "starting at",
      isPopular:true,
      features: [
        { text: "$50 monthly", available: true },
        { text: "Free Domain for 1 year", available: true },
        { text: "Modern Landing Page", available: true },
        { text: "Social Media Integration", available: true },
        { text: "Technical Support", available: true },
        { text: "Contact Form", available: true },
        { text: "Google Map", available: true },
        { text: "Customize Sections", available: true },
        { text: "E-commerce", available: false },
        { text: "CMS", available: false },
      ],
      buttonLink: "https://calendly.com/barciastech/30min",
      buttonText: "Book a FREE Consultation",
    },
    {
      title: "Enterprise",
      price: "$2,500",
      startingAt: "starting at",
      isPopular: false,
      features: [
        { text: "from $150 monthly", available: true },
        { text: "Free Domain for 1 year", available: true },
        { text: "Modern Landing Page", available: true },
        { text: "Social Media Integration", available: true },
        { text: "Contact Form", available: true },
        { text: "Google Map Integration", available: true },
        { text: "Customize Sections", available: true },
        { text: "E-commerce", available: true },
        { text: "CMS", available: true },
      ],
      buttonLink: "https://calendly.com/barciastech/30min",
      buttonText: "Book a FREE Consultation",
    },
  ];

  return (
    <>
     <section id="pricing" className="bsb-pricing-2 bg-light py-5 py-xl-8">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
            <h2 className="display-5 mb-4 mb-md-5 text-center text-danger">
            We offer great pricing plans for everyone.
            </h2>
            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row gy-5 gy-lg-0 gx-xl-5">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className={`col-12 col-lg-4 ${plan.isPopular ? 'position-relative' : ''}`}
              data-aos="zoom-in-up"
              data-aos-duration="1000"
            >
              <div
                className={`card border-0 border-bottom border-danger shadow-lg pt-md-4 pb-md-4 ${
                  plan.isPopular ? 'popular-card' : ''
                }`}
              >
                {/* Render "POPULAR" badge if the plan isPopular */}
                {plan.isPopular && (
                  <span
                    className="badge bg-danger text-white position-absolute"
                    style={{
                      top: "10px",
                      right: "10px",
                      padding: "10px",
                      fontSize: "14px",
                    }}
                  >
                    POPULAR
                  </span>
                )}

                <div className="card-body p-4 p-xxl-5">
                  <h2 className="display-4 mb-2 text-danger fw-bold">{plan.title}</h2>
                  <h4 className="display-3 fw-bold text-danger mb-0">
                    <span className="text-muted h4">{plan.startingAt}</span> {plan.price}
                  </h4>
                  <ul className="list-group list-group-flush mb-4">
                    {plan.features.map((feature, idx) => (
                      <li className="list-group-item" key={idx}>
                        {feature.available ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-check"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-x text-danger"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        )}
                        <span>{feature.text}</span>
                      </li>
                    ))}
                  </ul>
                  <PopupButton
                    className="btn bsb-btn-xl btn-danger rounded-pill"
                    url="https://calendly.com/barciastech/30min"
                    rootElement={document.getElementById("root")}
                    text={plan.buttonText}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    </>
  );
}
