import { useState } from 'react'
import { omit } from 'lodash'


const useForm = (callback) => {
    
    //Form values
    const [values, setValues] = useState({});
    //Errors
    const [errors, setErrors] = useState({});
    
    
    const validate = (event, name, value) => {
        //A function to validate each input values
        switch (name) {
            case 'name':
                if(value === undefined || value === ""){
                    // we will set the error stat
                    setErrors({
                        ...errors,
                        name:'Please enter a valid name'
                    })
                }else{
                    // set the error state empty or remove the error for username input
                    //omit function removes/omits the value from given object and returns a new object
                    let newObj = omit(errors, "name");
                    setErrors(newObj);
                }
                break;
        
            case 'email':
                if(
                    !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ){
                    setErrors({
                        ...errors,
                        email:'Enter a valid email address'
                    })
                }else{
                    let newObj = omit(errors, "email");
                    setErrors(newObj);
                }
            break;
            
            case 'phone':
                if(
                    !new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im).test(value)
                ){
                    setErrors({
                        ...errors,
                        phone:'Enter correct phone number xxx-xxx-xxxx.'
                    })
                }else{

                    let newObj = omit(errors, "phone");
                    setErrors(newObj);
                    
                }
            break;
            case 'business':
                if(value === undefined || value === ""){
                    setErrors({
                        ...errors,
                        business:'Enter business name.'
                    })
                }else{

                    let newObj = omit(errors, "business");
                    setErrors(newObj);
                    
                }
            break;

            case 'request':
                if(value === undefined || value === ""){
                    setErrors({
                        ...errors,
                        request:'Please enter request.'
                    })
                }else{

                    let newObj = omit(errors, "request");
                    setErrors(newObj);
                    
                }
            break;
            
            default:
                break;
        }
    }


  //A method to handle form inputs
    const handleChange = (event) => {
        //To stop default events    
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        validate(event,name,val);
        //Let's set these values in state
        setValues({
            ...values,
            [name]:val,
        })

    }

    const handleSubmit = (event) => {
        if(event) event.preventDefault();

        if(Object.keys(errors).length === 0 && Object.keys(values).length !==0 ){
            callback()
            setValues({name: '', email:'', phone: '', business: '', request: ''})

        }else{
            alert("Please check information and try again!");
        }
    }

    return {
        values,
        errors,
        handleChange,
        handleSubmit
    }
}

export default useForm