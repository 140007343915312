import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import HomeEspañol from "./Español/HomeEspañol";
import Blog from "./Components/Blog/Blog";
import Post from "./Components/Blog/Post";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/blog" element={<Blog/>}/>
          <Route path='/blogs/:postId' element={<Post />}/>
          <Route path="/:es" element={<HomeEspañol />}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
