import { Form } from 'react-bootstrap'
import { useEffect, useState } from "react";
import { useFirestore } from '../../hooks/useFirestore';
import './contact.css'
import AOS from "aos";
import "aos/dist/aos.css";
import MyModal from '../Modal/MyModal';


export default function Contact(){

    const [ name, setName ] = useState('')
    const [ email, setMail ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ business, setBusiness ] = useState('')
    const [ request, setRequest ] = useState('')
    const [ showModal, setShowModal] = useState(false)

    const { addDocument } = useFirestore('new-customers')

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    const doc = {
        name: name,
        email: email,
        phone: phone,
        business: business,
        request: request
    }

    function clearFields(){
      setName('')
      setMail('')
      setPhone('')
      setBusiness('')
      setRequest('')
    }

    const sendRequest = (e) => {
        e.preventDefault()
        addDocument(doc)
        setShowModal(true)
        clearFields()
    }

    return(
        <>
          <div id='contact' className="container-fluid">
              <div className="container text-center text-muted pt-5 w-75">
                <h1 className="display-3">¿Como podemos ayudarlo?</h1>
                <p className="lead">Por favor llene el formulario, uno de nuestros especialistas se comunicará con usted pronto
                para iniciar una consulta gratuita.</p>
              </div>
              <div className='container form bg-light p-4 shadow-lg rounded-3 border border-1 border-secondary' data-aos="fade-up" data-aos-duration="1000">
                <Form onSubmit={sendRequest}>
                    <Form.Control required  value={name} onChange={(e) => {setName(e.target.value)}} className='mb-3 lead' type='text'placeholder='Nombre' />
                    <Form.Control required value={email} onChange={(e) => {setMail(e.target.value)}} className='mb-3 lead' type='email'placeholder='Email' />
                    <Form.Control required value={phone} onChange={(e) => {setPhone(e.target.value)}} className='mb-3 lead' type='phone'placeholder='Numero de telefono' />
                    <Form.Control required value={business} onChange={(e) => {setBusiness(e.target.value)}} className='mb-3 lead' type='text' placeholder='Negocio' />      
                    <Form.Control required value={request} onChange={(e) => {setRequest(e.target.value)}} className='mb-3 lead' as={'textarea'} placeholder='Por favor diganos que necesita. Sitio Web, Perfil de Google, Social Media ....'/> 
                    <button type='submit' className='btn btn-secondary mt-3 submit-button'>Enviar Mensaje</button> 
                </Form>
              </div>
              <MyModal show={showModal} onHide={() => {setShowModal(false)}}/>
          </div>
        </>
    )
}