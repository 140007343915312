// components/FacebookPosts.js
import { Card, Col, Row } from 'react-bootstrap';
import { PopupButton } from 'react-calendly';

const FacebookPosts = () => {
  const posts = [
    {
      id: 1,
      title: 'Post 1',
      content: 'This is an example of a Facebook post showcasing our services.',
      image: './Assets/construction.jpg', // Replace with your image paths
    },
    {
      id: 2,
      title: 'Post 2',
      content: 'Check out our latest offerings!',
      image: './Assets/cleaning.jpg',
    },
    {
      id: 3,
      title: 'Post 3',
      content: 'Join us today and enjoy great deals!',
      image: './Assets/auto.jpg',
    },
  ];

  return (
    <div className="container text-center p-5 my-5">
      <div className='bg-light p-5 rounded shadow-lg'>
<h2 className="text-center display-4 mb-4 pb-3 text-danger fw-bold">¿Necesitas Publicaciones Profesionales para Redes Sociales?</h2>
<p className='lead text-muted pb-5'>Una publicación profesional y bien elaborada puede transformar la imagen de tu marca, captando la atención y generando confianza en tu audiencia. Por otro lado, publicaciones mal diseñadas o de apariencia amateur pueden dañar tu credibilidad y alejar a posibles clientes. Con los visuales correctos, el mensaje adecuado y consistencia, puedes destacar y dejar una impresión duradera. ¡Déjanos ayudarte a mejorar tu presencia en redes sociales!</p>
<Row xs={1} md={2} lg={3} className="g-4">
        {posts.map((post) => (
          <Col key={post.id}>
            <Card className='shadow-lg'>
              <Card.Img variant="top" src={require(`${post.image}`)} />
              {/* <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Text>{post.content}</Card.Text>
              </Card.Body> */}
            </Card>
          </Col>
        ))}
      </Row>
      <div className="text-center my-4 mt-5 pt-5">
      <h3 className='display-5 text-danger'>Nuestros Paquetes de Social Media </h3>
        <p className='lead'>Obten 10 posts por solo <strong>$200</strong></p>
        <p className='lead'>O 1 post por <strong>$30</strong></p>
        <PopupButton
                    className="btn bsb-btn-xl btn-danger rounded-pill"
                    url="https://calendly.com/barciastech/30min"
                    rootElement={document.getElementById("root")}
                    text='Agenda una consulta GRATIS'
                  />
      </div>
      </div>
    </div>
  );
};

export default FacebookPosts;
