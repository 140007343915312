import { Carousel } from 'react-bootstrap'
import './portfolio.css'

export default function Portfolio(){
    return(
      <>
        <div id='portfolio' className='container-fluid pb-5'>
          <div className='container pt-5 text-center' >
            <h1 className='display-3'>Our Portfolio</h1>
            <p className='lead'>Some of our latest designs.</p>
          </div>
            <Carousel className="container p-5">  
              <Carousel.Item>
                <img
                  className="img-fluid"
                  src={require('./Assets/analyics-1.jpg')}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <a className='btn btn-danger' href='https://ordenanow-a6fb6.web.app'>View website</a>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid"
                  src={require('./Assets/charts.jpg')}
                  alt="Second slide"
                />
                <Carousel.Caption>
                  <a className='btn btn-danger' href='https://poshrecoveryhome.web.app'>View website</a>
                </Carousel.Caption>
              </Carousel.Item>
              {/* <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={require('./Assets/analytics-3.jpg')}
                      alt="Third slide"
                    />
              </Carousel.Item> */}
              <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={require('./Assets/midwest-chiro.jpg')}
                      alt="Third slide"
                    />
                 <Carousel.Caption>
                  <a className='btn btn-danger' href='https://chiropractoroffice-fac89.web.app/'>View website</a>
                 </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={require('./Assets/hair-salon.jpg')}
                      alt="Third slide"
                    />
                 <Carousel.Caption>
                  <a className='btn btn-danger' href='https://hairsalon-6e82e.web.app'>View website</a>
                 </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={require('./Assets/JB-Construction.jpg')}
                      alt="Third slide"
                    />
                 <Carousel.Caption>
                  <a className='btn btn-danger' href='https://jbconstruction-ef7a8.web.app'>View website</a>
                 </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={require('./Assets/Mexican.jpg')}
                      alt="Third slide"
                    />
                 <Carousel.Caption>
                  <a className='btn btn-danger' href='https://elcharro-84460.web.app/'>View website</a>
                 </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={require('./Assets/tk.jpg')}
                      alt="Third slide"
                    />
                 <Carousel.Caption>
                  <a className='btn btn-danger' href='https://champyontaekwondousa.web.app/'>View website</a>
                 </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={require('./Assets/lanscaping.jpg')}
                      alt="Third slide"
                    />
                 <Carousel.Caption>
                  <a className='btn btn-danger' href='https://affordablelawncarechicago.web.app/'>View website</a>
                 </Carousel.Caption>
              </Carousel.Item>
              
            </Carousel>
          </div>
    </>)
}