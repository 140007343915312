import './navbar.css'
import { Navbar, Container, Nav, NavbarBrand, NavDropdown } from 'react-bootstrap'
import { PopupButton } from 'react-calendly';
import BarciasLogo from "../Logo/BarciasLogo.jsx"


export default function NavBar(){
    return (
        <Navbar className="navbar" fixed='top'  collapseOnSelect expand='md'>
          <Container>
          <NavbarBrand href="/">
          <BarciasLogo height={80} width={150}/>
           </NavbarBrand>
            <Navbar.Toggle className='toggle-toggle' aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className='collapse' id='responsive-navbar-nav' >
              <Nav className="ms-auto">
               <Nav.Link id='navlinks' href="/">Home</Nav.Link>
              </Nav>
              <Nav>
               <Nav.Link id='navlinks' href="#services">Services</Nav.Link>
              </Nav>
              <Nav>
               <Nav.Link id='navlinks' href="#process">Process</Nav.Link>
              </Nav>
              {/* <Nav>
               <Nav.Link id='navlinks' href="#analytics">Analytics</Nav.Link>
              </Nav> */}
              <Nav>
               <Nav.Link id='navlinks' href="#pricing">Pricing</Nav.Link>
              </Nav>
              {/* <Nav>
               <Nav.Link id='navlinks' href="/blog">Blog</Nav.Link>
              </Nav> */}
              <Nav>
               <Nav.Link id='navlinks' href="#contact">Contact</Nav.Link>
              </Nav>
              <Nav>
              <PopupButton id='nav-btn' className='btn m-auto  btn-md mt-2 btn-danger'
                      url="https://calendly.com/barciastech/30min"
                      /*
                       * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                       * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                       */
                      rootElement={document.getElementById("root")}
                      text="Book a FREE Consultation"
                     />
              </Nav>
              <NavDropdown title={<img className="flag-image" 
                 src={require('./Assets/usa.png')} 
                 alt="user pic"/>}  id="basic-nav-dropdown">
                <NavDropdown.Item href="/"><span><img   className='flag-image' src={require('./Assets/usa.png')} alt='usa'></img></span> English</NavDropdown.Item>
                <NavDropdown.Item href="/es"><span><img className='flag-image' src={require('./Assets/mexico.png')} alt='usa'></img></span> Español</NavDropdown.Item>
              </NavDropdown>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    )
}