import Analytics from "./AnalyticsSection/Analytics";
import Contact from "./ContactSection/Contact";
import Footer from "./Footer/Footer";
import HeroSection from "./HeroSection/HeroSection";
import NavBar from "./Navbar/NavBar";
import Portfolio from "./PortfolioSection/Portfolio";
import Process from "./ProcessSection/Process";
import Services from "./ServicesSection/Services";
import PricingModern from "./PricingSection/PricingModern";
import FAQ from "./FAQ/Faq";
import PackageSection from "./PackageSection/PackageSection";
import Ticker from "./Ticker/Ticker";
import FacebookPosts from "./FacebookPosts/Facebookposts";

export default function HomeEspañol(){
    return(
        <>
          <Ticker />
          <NavBar />
          <HeroSection />
          <PackageSection />
          <Services />
          <Process />
          <Analytics />
          <PricingModern />
          <FacebookPosts/>
          <Portfolio />
          <FAQ />
          <Contact />
          <Footer />
        </>
    )
}