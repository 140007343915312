import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import "./pricing.css";
import { PopupButton } from "react-calendly";

export default function PricingModern() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const pricingPlans = [
    {
      title: "Starter",
      price: "$750",
      startingAt: "primer año desde",
      features: [
        { text: "$25 mensual", available: true },
        { text: "Free Domain for 1 year", available: true },
        { text: "Modern Landing Page", available: true },
        { text: "Social Media Integration", available: false },
        { text: "Technical Support", available: false },
        { text: "No Contact Form", available: false },
        { text: "No Google Map", available: false },
        { text: "No Customize Sections", available: false },
        { text: "No E-commerce", available: false },
        { text: "No CMS", available: false },
      ],
      buttonLink: "https://calendly.com/barciastech/30min",
      buttonText: "Llámanos Hoy!",
    },
    {
      title: "Pro",
      price: "$1,500",
      startingAt: "primer año desde",
      isPopular:true,
      features: [
        { text: "$50 mensual", available: true },
        { text: "Free Domain for 1 year", available: true },
        { text: "Modern Landing Page", available: true },
        { text: "Social Media Integration", available: true },
        { text: "Technical Support", available: true },
        { text: "Contact Form", available: true },
        { text: "Google Map", available: true },
        { text: "Customize Sections", available: true },
        { text: "E-commerce", available: false },
        { text: "CMS", available: false },
      ],
      buttonLink: "https://calendly.com/barciastech/30min",
      buttonText: "Llámanos Hoy!",
    },
    {
      title: "Enterprise",
      price: "$2,500",
      startingAt: "primer año desde",
      isPopular: false,
      features: [
        { text: "desde $150 mensual", available: true },
        { text: "Free Domain for 1 year", available: true },
        { text: "Modern Landing Page", available: true },
        { text: "Social Media Integration", available: true },
        { text: "Contact Form", available: true },
        { text: "Google Map Integration", available: true },
        { text: "Customize Sections", available: true },
        { text: "E-commerce", available: true },
        { text: "CMS", available: true },
      ],
      buttonLink: "https://calendly.com/barciastech/30min",
      buttonText: "Llámanos Hoy!",
    },
  ];

  return (
    <>
      <section id="pricing" className="bsb-pricing-2 bg-light py-5 py-xl-8">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="display-5 mb-4 mb-md-5 text-center">
                Ofrecemos Excelentes Planes Para Negocios de Todos Tamaños.
              </h2>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row gy-5 gy-lg-0 gx-xl-5">
            {pricingPlans.map((plan, index) => (
              <div
                key={index}
                className="col-12 col-lg-4"
                data-aos="zoom-in-up"
                data-aos-duration="1000"
              >
                <div className="card border-0 border-bottom border-danger shadow-lg">
                  <div className="card-body p-4 p-xxl-5">
                    <h2 className="display-4 mb-1">{plan.title}</h2>
                    <h4 className="display-3 fw-bold text-danger mb-0">
                      <span className="text-muted h4">{plan.startingAt}</span>{" "}
                      {plan.price}
                    </h4>
                    <ul className="list-group list-group-flush mb-4">
                      {plan.features.map((feature, i) => (
                        <li key={i} className="list-group-item">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className={`bi bi-${
                              feature.available ? "check" : "x text-danger"
                            }`}
                            viewBox="0 0 16 16"
                          >
                            <path
                              d={
                                feature.available
                                  ? "M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                                  : "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                              }
                            />
                          </svg>
                          <span>
                            <strong>{feature.text}</strong>
                          </span>
                        </li>
                      ))}
                    </ul>
                    <PopupButton id='nav-btn' className='btn m-auto  btn-md mt-2 btn-danger'
                      url="https://calendly.com/barciastech/30min"
                      /*
                       * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                       * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                       */
                      rootElement={document.getElementById("root")}
                      text={plan.buttonText}
                     />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
