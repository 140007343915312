import './services.css'
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";


const services = [
  {
    id: 1,
    icon: './Assets/web.jpg',
    title: 'Web Development',
    description:'We build custom web applications and websites using modern technologies and best practices.',
    tags: ['webdevelopment']
  },
  {
    id: 2,
    icon: './Assets/storefront.png',
    title: 'Branding',
    description:'We help businesses establish a strong brand identity through logo design, color schemes, typography, and messaging.',
    tags: ['webdevelopment', 'branding']
  },
  {
    id: 3,
    icon: './Assets/digital.jpg',
    title: 'Digital Marketing',
    description:'We offer a range of digital marketing services, including SEO, social media marketing, email marketing, and paid advertising to generate more leads to your business.',
    tags: ['webdevelopment','branding', 'digital marketing']
  },
  {
    id: 4,
    icon: './Assets/support.jpeg',
    title: 'Maintenance and Support',
    description:'We provide ongoing maintenance and support for websites and web applications to ensure they stay up-to-date and secure.',
    tags: ['webdevelopment', 'support', 'maintenence']
  },
  {
    id:5,
    icon: './Assets/ai.jpeg',
    title: 'AI Consulting',
    description:'Transform your business with tailored AI solutions. From automating workflows to unlocking data insights, we guide you in integrating AI technologies that drive efficiency and growth. Let’s make your business smarter, faster.',
    tags: ['webdevelopment', 'AI', 'consulting']
  },
  {
    id:6,
    icon: './Assets/whitevan.png',
    title: 'Complete Branding Solutions',
    description: "We'll help you build a cohesive brand across both digital and physical platforms. From custom signage, t-shirts, stickers, and banners to car decals, we ensure your brand stands out everywhere. Whether online or in the real world, we deliver high-quality, consistent branding that connects with your customers.",
    tags: ['branding', 'banners', 'decals', 'shirts']
  }
];

export default function Services(){

  useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);

    return(
        <>
        <div id="services" className="container-fluid pb-5 pt-4 mw-100">
           <div className="container text-center pt-3 pb-5">
               <h1 className="display-3 text-white">Services</h1>
               <p className='lead text-white'>Barcias Tech specializes in helping small to medium size businesses. Our services include
               web design, branding, digital marketing and much more. 
               We understand the budget constraints of small businesses thus we provide
               high quality affordable services.
               </p>
           </div>
           
           <div className='container bg-light p-4 rounded-3 shadow-lg'>

            {services.map(service => (
                <div className='row d-flex align-items-center pt-4 border-bottom pb-4'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                      <img className='img-thumbnail shadow-lg rounded-3' src={require(`${service.icon}`)} alt={service.title} />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 fw-bold text-danger'>{service.title}</h1>
                      <p className='lead pt-3'> {service.description} </p>
                  </div>
                </div>
            ))}
            
           </div>
        </div> 
        </>
    )
}