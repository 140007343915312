// components/Ticker.js
import { useEffect, useState, useMemo } from 'react';
import './ticker.css'; // Ensure this path is correct based on your project structure

export default function Ticker() {
  const [tickerText, setTickerText] = useState('');
  const tickerMessages = useMemo(() => [
    'Oferta por tiempo limitado para nuevos clientes: ¡Obtén un paquete completo de branding! ¡Oferta especial: 15 camisetas, 100 pegatinas y un nuevo sitio web por $2000! ¡Contáctanos hoy para obtener más información sobre nuestros servicios!'
  ], []); //
  // Function to handle the ticker scrolling effect
  useEffect(() => {
    let currentIndex = 0;

    const updateTicker = () => {
      setTickerText(tickerMessages[currentIndex]);
      currentIndex = (currentIndex + 1) % tickerMessages.length;
    };

    const tickerInterval = setInterval(updateTicker, 10); // Change every 4 seconds
    updateTicker(); // Set initial text immediately

    return () => clearInterval(tickerInterval);
  }, [tickerMessages]); 
  return (
    <div className="ticker-container">
      <div className="ticker">
        <span className="ticker-text">{tickerText}</span>
      </div>
    </div>
  );
}
