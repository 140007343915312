const BarciasLogo = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 118.278 74.033"
      version="1.1"
      id="svg1"
    >
      <g id="layer1">
        <g id="g2" style={{ fill: "#000000" }}>
          <g id="g3" style={{ fill: "#e82b35", fillOpacity: 1 }}>
            <g id="g1" transform="matrix(1,0,0,0.98816568,0,0.00415313)">
              <g id="g4">
                <rect
                  style={{ fill: "#10263a", fillOpacity: 1, color:'#ffffff', strokeWidth: 1.216 }}
                  id="rect1"
                  width={width}
                  height={height}
                  x="0"
                  y="0.3509"
                />
                <text
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontSize: "10.1177px",
                    fontFamily: "'Safira March Personal Use'",
                    textAlign: "end",
                    letterSpacing: "-0.301634px",
                    wordSpacing: "0.394591px",
                    direction: "rtl",
                    textAnchor: "end",
                    fill: "#e82b35",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeWidth: 0.5281,
                  }}
                  x="44.904522"
                  y="57.203529"
                  id="text2"
                >
                  <tspan
                    sodipodiRole="line"
                    id="tspan2"
                    style={{ fill: "#e82b35", strokeWidth: 1.5281 }}
                    x="44.904522"
                    y="57.203529"
                  />
                  {/* <tspan
                    sodipodiRole="line"
                    id="tspan3"
                    style={{
                      fontSize: "5.64444px",
                      fontFamily: "'Julius Sans One'",
                      letterSpacing: "1.5875px",
                      fill: "#e82b35",
                      stroke: "#e82b35",
                      strokeWidth: 0.5281,
                    }}
                    x="44.904522"
                    y="69.850655"
                  >
                    STUDIO
                  </tspan> */}
                </text>
                <circle
                  style={{
                    fill: "#e82b35",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeWidth: "0.301706",
                  }}
                  id="path1"
                  cx="59.366482"
                  cy="23.634415"
                  r="19.849148"
                />
                <text
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: "bold",
                    fontSize: "6.45583px",
                    fontFamily: "'Safira March Personal Use'",
                    textAlign: "end",
                    letterSpacing: "1.34144px",
                    fill: "#e82b35",
                    stroke: "#f4f5f5",
                    strokeWidth: 0.1871,
                  }}
                  x="5.7733259"
                  y="64.637314"
                  id="text1"
                  transform="scale(1.0952577,0.91302714)"
                >
                  <tspan
                    sodipodiRole="line"
                    id="tspan1"
                    style={{
                      fontSize: "6.45583px",
                      fontFamily: "'Safira March Personal Use'",
                      fill: "#e82b35",
                      stroke: "#e82b35",
                      strokeWidth: 0.1871,
                    }}
                    x="5.7733259"
                    y="64.637314"
                    dx="1.59 0 0 0 0 0 0 0 0 0 0"
                  >
                    BARCIAS TECH
                  </tspan>
                </text>
                <path
                  style={{ fill: "#ffffff", strokeWidth: 0.3602 }}
                  d="M 46.923517,31.445266 c -0.332142,-0.18409 -0.676562,-0.80765 -0.677131,-1.22592 l -5.05e-4,-0.35821 5.406038,0.0246 5.40604,0.0246 0.101829,0.20428 c 0.260574,0.52273 0.202621,0.51054 2.429489,0.51054 2.22687,0 2.1689,0.0122 2.42951,-0.51054 l 0.10184,-0.20428 h 5.3822 5.3822 l 0.0298,0.30986 c 0.0374,0.38783 -0.28584,0.98519 -0.64925,1.19986 -0.24592,0.14528 -1.28358,0.15793 -12.69106,0.15483 -10.129972,-0.003 -12.465311,-0.0267 -12.651079,-0.12965 z m 25.34067,-0.39349 c 0.15853,-0.15854 0.32791,-0.4266 0.37641,-0.5957 l 0.0882,-0.30744 h -5.21212 -5.21214 l -0.1201,0.27133 c -0.19788,0.44705 -0.4309,0.49115 -2.59511,0.49115 -2.164214,0 -2.397234,-0.0441 -2.595117,-0.49115 l -0.120133,-0.27133 h -5.212123 -5.21213 l 0.08638,0.3012 c 0.09705,0.3384 0.423658,0.728 0.710448,0.84747 0.10484,0.0437 5.711823,0.0711 12.459965,0.0611 l 12.26934,-0.0183 z m -24.77926,-8.3897 c 0,-7.73425 -0.02201,-7.41168 0.535153,-7.84992 l 0.253034,-0.19904 h 11.316183 11.31618 l 0.25304,0.19904 c 0.55714,0.43824 0.53514,0.11567 0.53514,7.84992 v 7.10533 h -12.10436 -12.10437 z m 23.92281,-0.11702 c 0,-6.34665 -0.0152,-7.05335 -0.15542,-7.25363 -0.3381,-0.4827 -0.40024,-0.48769 -6.07065,-0.48769 h -5.27908 l 0.0102,0.28572 0.0102,0.28571 5.09908,0.002 c 2.8045,7.2e-4 5.19731,0.0279 5.31735,0.06 0.49799,0.1334 0.49656,0.1142 0.49656,6.68698 0,4.00694 -0.0342,6.18854 -0.10005,6.37736 -0.055,0.15787 -0.20716,0.33582 -0.33807,0.39546 -0.30037,0.13686 -21.24988,0.15619 -21.608237,0.02 -0.13493,-0.0513 -0.277767,-0.21754 -0.339791,-0.39546 -0.07482,-0.21462 -0.107006,-2.13909 -0.107006,-6.3973 0,-6.57279 -0.0015,-6.55359 0.496559,-6.68699 0.120045,-0.0321 2.512854,-0.0591 5.317349,-0.06 l 5.099086,-0.002 0.0102,-0.28571 0.0102,-0.28572 h -5.279078 c -5.670409,0 -5.732548,0.004 -6.070648,0.48769 -0.140214,0.20028 -0.155426,0.90698 -0.155426,7.25363 v 7.02299 h 11.87437 11.87436 z"
                  id="path2"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BarciasLogo;
