import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import './process.css'

export default function Process() {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


    return (
    <section id="process" class="p-0 m-0 bg-light">
    <div class="container pb-5 pt-5">
        <div class="title mb-5 text-center">
        <h3 className="display-3 text-muted">Nuestro <span class="site-color">Proceso</span></h3>
        <p className="lead pb-5 pt-2">
         Nuestro proceso simplificado le permitirá elegir la
        mejor opción para su negocio y crear una imagen de marca consistente en todas las
        plataformas digitales. Todos los negocios son únicos, no se conforme con un enfoque estándar.</p>
    </div>
    <div className="container">
    <div className="row">
        <div className="col-lg-6 mb-5 " data-aos="zoom-in-up" data-aos-duration="1000">
            <img className="agency-image shadow-lg" src={require('./Assets/agency.png')} alt='agency' />
        </div>
        <div className="col-lg-6 col-md-12">
        <div class="process-box process-left" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Paso</p>
                                <h2 class="m-0 p-0">01</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <h5>Metas y Expectativas</h5>
                            <p className="lead"><small>Nuestro equipo trabajará con usted para establecer objetivos basados ​​en las necesidades de su negocio.</small></p>
                        </div>
                    </div>
                </div>

                <div class="process-box process-left" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Paso</p>
                                <h2 class="m-0 p-0">02</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <h5>Prototipo</h5>
                            <p className="lead"><small>
                              En 48 horas recibirás un wireframe o maqueta de tu sitio web, logo, etc. </small></p>
                        </div>
                    </div>
                </div>

                <div class="process-box process-left" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Paso</p>
                                <h2 class="m-0 p-0">03</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <h5>Diseño & Desarrollo</h5>
                            <p className="lead"><small>Una vez que el prototipo sea aprobado por su equipo, iniciaremos el proceso de diseño/desarrollo.</small></p>
                        </div>
                    </div>
                </div>

                <div class="process-box process-left" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Paso</p>
                                <h2 class="m-0 p-0">04</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <h5>Resultados</h5>
                            <p className="lead"><small>Te ayudaremos a medir los resultados de tu nueva estrategia de mercadotecnia. Los informes mensuales estarán disponibles según tu nivel de suscripción.</small></p>
                        </div>
                    </div>
                </div>

                <div class="process-box process-left" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Paso</p>
                                <h2 class="m-0 p-0">05</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <h5>Crecimiento</h5>
                            <p className="lead"><small>
A medida que cambien las necesidades digitales de su negocio, nuestro equipo lo ayudará a adaptarse y crecer.  </small></p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>

    </div>
</section>
    )
}