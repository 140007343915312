// components/FacebookPosts.js
import { Card, Col, Row } from 'react-bootstrap';
import { PopupButton } from 'react-calendly';

const FacebookPosts = () => {
  const posts = [
    {
      id: 1,
      title: 'Post 1',
      content: 'This is an example of a Facebook post showcasing our services.',
      image: './Assets/construction.jpg', // Replace with your image paths
    },
    {
      id: 2,
      title: 'Post 2',
      content: 'Check out our latest offerings!',
      image: './Assets/cleaning.jpg',
    },
    {
      id: 3,
      title: 'Post 3',
      content: 'Join us today and enjoy great deals!',
      image: './Assets/auto.jpg',
    },
  ];

  return (
    <div className="container-fluid text-center p-5 my-5 bg-white rounded">
      <div className='bg-light p-5 rounded-3 shadow-lg'>
      <h2 className="text-center display-4 mb-4 pb-3 text-danger fw-bold">Need Professional Looking Social Media Posts?</h2>
      <p className='lead muted pb-5'>A professional, well-crafted media post can transform your brand’s image, capturing attention and building trust with your audience. On the other hand, poorly designed, amateur posts can harm credibility and turn potential customers away. With the right visuals, messaging, and consistency, you can stand out and make a lasting impression. Let us help you elevate your social media presence!</p>
      <Row xs={1} md={2} lg={3} className="g-4">
        {posts.map((post) => (
          <Col key={post.id}>
            <Card className='shadow-lg '>
              <Card.Img variant="top" src={require(`${post.image}`)} />
              {/* <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Text>{post.content}</Card.Text>
              </Card.Body> */}
            </Card>
          </Col>
        ))}
      </Row>
      <div className="text-center my-4 mt-5 pt-5">
        <h3 className='display-5 text-danger'>Our Social Media Packages</h3>
        <p className='lead'>Get 10 posts for only <strong>$200</strong></p>
        <p className='lead'>Or choose a single post for <strong>$30</strong></p>
        <PopupButton
                    className="btn bsb-btn-xl btn-danger rounded-pill"
                    url="https://calendly.com/barciastech/30min"
                    rootElement={document.getElementById("root")}
                    text='Book a FREE Consultation'
                  />
      </div>
      </div>
    </div>
  );
};

export default FacebookPosts;
