import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const faqItems = [
  {
    question: '¿Cuál es el precio inicial para un sitio web básico?',
    answer: 'Nuestro sitio web básico comienza en $750, lo que incluye un dominio y alojamiento gratuitos durante 1 año, junto con una sección de héroe, sección sobre nosotros y más. Si necesita personalizaciones adicionales, el precio puede variar.'
  },
  {
    question: '¿Ofrecen soluciones de desarrollo web a medida?',
    answer: 'Sí, nos especializamos en crear aplicaciones web y sitios web personalizados según las necesidades específicas de su negocio. Nuestros precios varían según la complejidad y los requisitos de su proyecto.'
  },
  {
    question: '¿Qué está incluido en sus servicios de marketing digital?',
    answer: 'Nuestros servicios de marketing digital incluyen SEO, marketing en redes sociales, email marketing y publicidad pagada. Ayudamos a las empresas a generar clientes potenciales y aumentar su presencia en línea.'
  },
  {
    question: '¿Cómo puedo programar una consulta para consultoría en IA?',
    answer: 'Puede programar una consulta completando el formulario de contacto en nuestro sitio web. Nos pondremos en contacto para discutir cómo la IA puede integrarse en las operaciones de su negocio para mejorar la eficiencia y el crecimiento.'
  },
  {
    question: '¿Cuál es la diferencia entre los planes de precios Standard y Premium?',
    answer: 'El plan Standard comienza en $1,500 e incluye características adicionales como formularios de contacto, integración con redes sociales y Google Maps. El plan Premium, que comienza en $2,500, ofrece más personalización y opciones avanzadas como Google Business Profile y secciones personalizadas adicionales.'
  },
  {
    question: '¿Qué incluye su servicio de branding?',
    answer: 'Nuestros servicios de branding cubren todo, desde el diseño de logotipos, esquemas de colores, tipografía y mensajes para asegurar que la identidad de su marca sea sólida en todas las plataformas digitales y físicas.'
  },
  {
    question: '¿Ofrecen mantenimiento y soporte continuo para sitios web?',
    answer: 'Sí, ofrecemos servicios de mantenimiento y soporte para todos los sitios web que desarrollamos para garantizar que estén actualizados, seguros y funcionando correctamente. Nuestros planes Premium y Elite incluyen soporte mensual.'
  },
  {
    question: '¿Cómo puedo obtener una cotización personalizada para mi proyecto?',
    answer: 'Si necesita un nivel más alto de personalización o funciones más avanzadas, puede contactarnos para una consulta gratuita. Evaluaremos sus necesidades y le proporcionaremos una cotización personalizada según sus requisitos.'
  },
  {
    question: '¿Puedo actualizar mi sitio web más adelante si comienzo con un paquete básico?',
    answer: '¡Absolutamente! Siempre puede actualizar su sitio web para incluir más funciones a medida que su negocio crezca. Ofrecemos opciones de actualización flexibles para todos nuestros planes de precios.'
  },
  {
    question: '¿Qué soluciones de branding físico ofrecen?',
    answer: 'Nuestras soluciones completas de branding incluyen letreros personalizados, camisetas, stickers, banners y calcomanías para automóviles para ayudar a que su marca se destaque tanto en espacios digitales como físicos.'
  }
];


// Step 2: Map through the array to render the Accordion
const FAQ = () => {
  return (
    <div className="container-fluid bg-light pb-5 pt-4 mw-100 border p-5">
      <div className="container text-center pt-3 pb-5">
        <h1 className="display-3 font-weight-bold text-muted">FAQs</h1>
        <p className='lead text-muted'>¿Tienes preguntas sobre nuestros servicios? Consulta nuestras preguntas más frecuentes a continuación.
        Si necesitas más ayuda, no dudes en contactarnos.
        </p>
        </div>
    <Accordion flush>
      {faqItems.map((item, index) => (
        <Accordion.Item eventKey={index.toString()} key={item.id}>
          <Accordion.Header className='display-3'>{item.question}</Accordion.Header>
          <Accordion.Body>{item.answer}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
    </div>
  );
};

export default FAQ;
