import { Button, Modal } from "react-bootstrap";
import './modal.css'

export default function MyModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <i className="bi bi-check2-circle checkmark text-success"></i>
          <h4 className="display-4">Gracias! {props.name}</h4>
          <p className="lead">
           Uno de nuestros especialistas lo contactara pronto.
          </p>
          <Button className="btn-danger mt-3" onClick={props.onHide}>Cerrar</Button>
        </Modal.Body>
      </Modal>
    );
  }