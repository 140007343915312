import './services.css'
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function Services(){

  useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);

    return(
        <>
        <div id="services" className="container-fluid pb-5 pt-4 mw-100">
           <div className="container text-center pt-3 pb-5">
               <h1 className="display-3 text-white">Servicios</h1>
               <p className='lead text-white'>
                Barcias Tech se especializa en ayudar a las pequeñas y medianas empresas. Nuestros servicios incluyen
                diseño web, branding, marketing digital y mucho más.
                Entendemos las limitaciones presupuestarias de las pequeñas y medianas empresas, por lo que proporcionamos
                servicios asequibles de alta calidad.
               </p>
           </div>
           
           <div className='container bg-light p-4 rounded-3 shadow-lg'>
             {/* Web design section */} 
               <div className='row d-flex align-items-center pt-4 border-bottom pb-4'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='service-image shadow-lg rounded-3' src={require('./Assets/web-design.jpg')} alt='web-design' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>Diseño Web</h1>
                      <p className='lead pt-3'>
                      Un sitio web compatible con dispositivos móviles
                      es esencial en el mercado competitivo de hoy. Un sitio web profesionalmente elaborado
                      aumenta la credibilidad comercial y los ingresos de tu negocio.
                      Permítanos ayudarlo a crear un hermoso sitio web para posicionar
                      su negocio por encima de la competencia.  </p>
                  </div>
               </div>
             {/* End of Web design section */} 

                {/* Ecommerce design section */} 
                <div className='row d-flex align-items-center pt-4 border-bottom pb-4'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='service-image shadow-lg rounded-3' src={require('./Assets/ecommerce.png')} alt='web-design' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>E-Commerce</h1>
                      <p className='lead pt-3'>Vende tus productos o servicios en línea. Dependiendo de sus 
                      necesidades y presupuesto, hay varias opciones para elegir.  </p>
                  </div>
               </div>
             {/* Ecommerce of Web design section */} 

             {/* Branding section */} 
                <div className='row d-flex align-items-center pt-4 border-bottom pb-4'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='service-image shadow-lg rounded-3' src={require('./Assets/branding.jpg')} alt='branding' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>Mercadotecnia Digital</h1>
                      <p className='lead pt-3'>Un negocio exitoso necesita un mercadotecnia digital efectiva.
                      Nuestro equipo de expertos puede ayudarle a crear la estrategia perfecta para su negocio.
                      Una gran estrategia utiliza una variedad de canales digitales como la optimización de motores de búsqueda (SEO), las redes sociales,
                      campañas de correo electrónico y sitios web compatibles con dispositivos móviles.
                      </p>
                  </div>
               </div>
             {/* Social Media section */} 

             {/* Branding section */} 
             <div className='row d-flex align-items-center pt-4 border-bottom pb-5'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='service-image shadow-lg rounded-3' src={require('./Assets/social-media.png')} alt='social-media' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>Social Media</h1>
                      <p className='lead pt-3'>
                      Maximice la presencia de su negocio en línea. En Barcias podemos
                      ayudarlo a crear gráficos y videos personalizados para redes sociales para estandarizar su marca y
                      darle a su negocio un aspecto profesional en todos los canales de medios digitales.
                      </p>
                  </div>
               </div>
             {/* Google Profile section */} 

             {/* Branding section */} 
                <div className='row d-flex align-items-center pt-4 border-bottom pb-4'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='service-image shadow-lg rounded-3' src={require('./Assets/google.png')} alt='google' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>Perfil Empresarial de Google</h1>
                      <p className='lead pt-3'>Creamos perfiles optimizados de Google Business. Un
                      perfil de Google Business optimizado, aumentará el tráfico a su sitio web y
                      localizacion fisica. Y con las integraciones correctas, sus clientes podrán
                      hacer pedidos en línea o contactarlo de manera facil y rapida.
                      </p>
                  </div>
               </div>
             {/* End of Google Profile section */} 

             {/* Digital Media */} 
             <div className='row d-flex align-items-center pt-4 pb-4'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='service-image shadow-lg rounded-3' src={require('./Assets/digital-media.png')} alt='google' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>Digital Media</h1>
                      <p className='lead pt-3'>Nuestro equipo de medios digitales puede ayudarlo a filmar, editar y crear videos o gráficos sorprendentes. 
                      Un gran sitio web comienza con excelentes medios digitales.
                      </p>
                  </div>
               </div>
             {/*End of Digital Media */}
              
           </div>
        </div> 
        </>
    )
}