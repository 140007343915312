import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import './process.css'

export default function Process() {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


    return (
    <section id="process" class="p-0 m-0 bg-white">
    <div class="container bg-light p-5 mt-5 rounded shadow-lg mb-5 pb-5 pt-5">
        <div class="title mb-5 text-center">
        <h3 className="display-3 text-danger fw-bold">Our <span class="site-color">Process</span></h3>
        <p className="lead">Our streamlined process will allow you to choose the 
        best option for your business and create a consistent brand image across 
        digital platforms. All businesses are unique, do not settle for a cookie-cutter aproach.</p>
    </div>
    <div className="container">
    <div className="row">
        <div className="col-lg-6 mb-5 " data-aos="zoom-in-up" data-aos-duration="1000">
            <img className="agency-image shadow-lg" src={require('./Assets/agency.jpg')} alt='agency' />
        </div>
        <div className="col-lg-6 col-md-12">
        <div class="process-box process-left" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Step</p>
                                <h2 class="m-0 p-0">01</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                        <h5 className="lead fw-bold text-danger">Goals and Expectations</h5>
                            <p className="lead"><small>Our team will work with you to set goals based on your business needs.</small></p>
                        </div>
                    </div>
                </div>

                <div class="process-box process-left" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Step</p>
                                <h2 class="m-0 p-0">02</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                        <h5 className="lead fw-bold text-danger">Prototype</h5>
                            <p className="lead"><small>In 48 hours you will receive a wireframe or muck-up of your website, logo, etc.  </small></p>
                        </div>
                    </div>
                </div>

                <div class="process-box process-left" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step bg-danger">
                                <p class="m-0 p-0">Step</p>
                                <h2 class="m-0 p-0">03</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <h5 className="lead fw-bold text-danger">Design and Development</h5>
                            <p className="lead"><small>Once the prototype is approved by your team, We will start the design / development process. </small></p>
                        </div>
                    </div>
                </div>

                <div class="process-box process-left" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Step</p>
                                <h2 class="m-0 p-0">04</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                        <h5 className="lead fw-bold text-danger">Measure</h5>
                            <p className="lead"><small>We will help you measure the results of your new marketing strategy. Monthly reports will be available depending on subscription level. </small></p>
                        </div>
                    </div>
                </div>

                <div class="process-box process-left" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Step</p>
                                <h2 class="m-0 p-0">05</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                        <h5 className="lead fw-bold text-danger">Growth</h5>
                            <p className="lead"><small>As your business digital needs change, our team will help you adapt and grow.  </small></p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>

    </div>
</section>
    )
}