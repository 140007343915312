import { Button, Modal } from "react-bootstrap";
import './modal.css'

export default function MyModal(props) {

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
           { props.children }
           <hr></hr>
           <div className="text-center">
             <Button className="btn-danger mt-3" onClick={props.onHide}>Ok</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }